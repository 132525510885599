/** @jsx jsx */
import { Fragment } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  jsx,
  Text,
} from 'theme-ui'
import SectionBackgroundAccent from '~/assets/images/backgrounds/section-background-accent.inline.svg'
import Logo from '~/assets/images/logos/maker-wine-logo.inline.svg'
import RichTextRenderer from '~/components/Content/RichText'
import { isColorDark } from '~/utils/misc'

const MakerLinksPage = ({ maker }) => {
  const { color } = maker

  const backgroundColor = color ? `#${color}` : 'backgroundMedium'
  const textColor = !isColorDark(color) ? 'black' : 'white'

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Box
        pt={[3, 5, 7]}
        pb={[1, 2, 4]}
        bg={backgroundColor}
        sx={{ position: 'relative' }}
      >
        <Container>
          <Grid columns={12}>
            <Box sx={{ gridColumn: ['1 / -1', '2 / 12', '3 / 11', '4 / 10'] }}>
              <Flex
                mb={['20px', '28px']}
                sx={{
                  alignItems: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                <MakerLinksPageHeader maker={maker} textColor={textColor} />
              </Flex>
              <MakerLinksPageLinks maker={maker} />
            </Box>
          </Grid>
        </Container>
        <Box
          color={backgroundColor}
          sx={{
            background: 'transparent',
            bottom: '-32px',
            height: '32px',
            left: '50%',
            pointerEvents: 'none',
            position: 'absolute',
            transform: 'translateX(calc(-50% + 80px))',
            width: ['1500px', '2000px'],
            zIndex: '1',
          }}
        >
          <SectionBackgroundAccent />
        </Box>
      </Box>
      <Box pt={[6, 8]} pb={[6, 8, 10]}>
        <MakerLinksPageFooter
          backgroundColor={backgroundColor}
          textColor={textColor}
        />
      </Box>
    </Box>
  )
}

const MakerLinksPageHeader = ({ maker, textColor }) => {
  const { mainImage, name, wineryName } = maker

  return (
    <Fragment>
      {mainImage && (
        <Box mb={[2, 3]}>
          <GatsbyImage
            image={mainImage.gatsbyImageData}
            alt={mainImage.description}
            loading="eager"
            sx={{
              borderRadius: '50%',
              maxWidth: '148px',
              maxHeight: '148px',
              overflow: 'hidden',
              transform: 'translateZ(0)',
            }}
          />
        </Box>
      )}
      <Box sx={{ color: textColor }}>
        {name && (
          <Heading as="h1" variant="title2" mb={0}>
            {name}
          </Heading>
        )}
        {wineryName && (
          <Text as="p" variant="body2" mt={0} sx={{ fontWeight: 'medium' }}>
            {wineryName}
          </Text>
        )}
      </Box>
    </Fragment>
  )
}

const MakerLinksPageLinks = ({ maker }) => {
  const { additionalLink, article, packs, pronouns } = maker
  const linkPronoun = pronouns?.split('/')?.[1] || 'their'

  return (
    <Box as="ul" m={0} p={0} sx={{ width: '100%' }}>
      {article?.slug && (
        <MakerLinksPageLink
          to={`/blog/${article.slug}/`}
          label={`Read ${linkPronoun} story`}
        />
      )}
      {packs.map((pack) => (
        <MakerLinksPageLink
          key={`maker-pack-link-${pack.slug}`}
          to={`/products/${pack.slugUrl || pack.slug}/`}
          label={`Shop ${linkPronoun} ${pack.name}`}
        />
      ))}
      {additionalLink && (
        <MakerLinksPageItem>
          <RichTextRenderer richText={additionalLink} optionSet="ctaLinktree" />
        </MakerLinksPageItem>
      )}
      <MakerLinksPageLink
        to="/can-club/?promo=qrclub"
        label="Join the Can Club"
        sublabel="25% off!"
      />
      <MakerLinksPageLink to="/about/" label="About Maker" />
    </Box>
  )
}

const MakerLinksPageItem = ({ children }) => {
  return (
    <Box
      as="li"
      m={0}
      mb={2}
      p={0}
      sx={{
        listStyleType: 'none',
        textAlign: 'center',
        a: {
          width: '100%',
        },
      }}
    >
      {children}
    </Box>
  )
}

const MakerLinksPageLink = ({ label, sublabel, to }) => {
  return (
    <MakerLinksPageItem>
      <Button as={Link} to={to} variant="linktree">
        {label}
        {sublabel && (
          <Text
            as="span"
            mt="4px"
            sx={{ color: 'brandTertiary', display: 'block' }}
          >
            {sublabel}
          </Text>
        )}
      </Button>
    </MakerLinksPageItem>
  )
}

const MakerLinksPageFooter = ({ backgroundColor, textColor }) => {
  return (
    <Flex sx={{ alignItems: 'center', flexDirection: 'column' }}>
      <Box
        mb={5}
        sx={{
          a: {
            backgroundColor: backgroundColor,
            borderRadius: '30px',
            color: textColor,
            display: 'inline-block',
            fontWeight: 'medium',
            pt: 1,
            px: 3,
            pb: '12px',
            textDecoration: 'none',
            variant: 'text.body1',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        }}
      >
        <a
          href="https://www.instagram.com/makerwine/"
          target="_blank"
          rel="noreferrer"
          title="Maker Wine on Instagram (opens in a new window)"
        >
          @makerwine
        </a>
      </Box>
      <Box sx={{ left: '2px', position: 'relative' }}>
        <Link to="/" aria-label="Maker website homepage">
          <Logo height="35px" />
        </Link>
      </Box>
    </Flex>
  )
}

export default MakerLinksPage
